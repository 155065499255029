import { createFeatureSelector } from '@ngrx/store';
import { AuthState } from './auth.reducers';
import { AppStateEntities } from '../app.state';
import { AuthMode } from 'src/core/enums/permissions.enum';

export const authStoreFeature = createFeatureSelector<Readonly<AuthState>>(
  AppStateEntities.authStore,
);

export const authModeFeature = createFeatureSelector<Readonly<AuthMode>>(
  AppStateEntities.authModeStore,
);
